import { Alert, AnotherData, Dashboard, Logs, Page, PageResponse, Sitemap, StateType, OperatorResponse } from './state.model';

const mutations = {
  CLEAN_STORE(state: StateType): void {
    state.anotherData = undefined;
    state.dashboard = undefined;
    state.images = undefined;
    state.isLoading = false;
    state.issue = undefined;
    state.logs = undefined;
    state.page = undefined;
    state.pages = undefined;
    state.operator = undefined;
  },
  SET_ALERT(state: StateType, alert: Alert): void {
    state.alert = alert;
  },
  SET_ANOTHER_DATA(state: StateType, response: AnotherData): void {
    state.anotherData = response;
  },
  SET_CRAWLER_HISTORY(state: StateType, response: PageResponse): void {
    state.history = response;
  },
  SET_DASHBOARD(state: StateType, response: Dashboard): void {
    state.dashboard = response;
  },
  SET_DETAILS_PAGE(state: StateType, response: Page): void {
    state.page = response;
  },
  SET_FINISH_LOADING(state: StateType): void {
    state.isLoading = false;
  },
  SET_IMAGES(state: StateType, response: ImageData[]): void {
    state.images = response;
  },
  SET_ISSUE(state: StateType, response: Record<string, number>): void {
    state.issue = response;
  },
  SET_LOGS(state: StateType, response: Logs): void {
    state.logs = response;
  },
  SET_OPERATOR_DATA(state: StateType, response: OperatorResponse): void {
    state.operator = response;
  },
  SET_PAGES(state: StateType, response: PageResponse): void {
    state.pages = response;
  },
  SET_RECOMENDATION_COMPARE(state: StateType, compare: Record<string, number>): void {
    state.recomendationCompare = compare;
  },
  SET_SCRAPP_ID(state: StateType, id: string): void {
    state.scrappID = id;
  },
  SET_SEARCH(state: StateType, response: PageResponse): void {
    state.searchResult = response;
  },
  SET_SITEMAP(state: StateType, response: Sitemap): void {
    state.sitemap = response;
  },
  SET_SITE_ID(state: StateType, id: string): void {
    state.siteID = id;
  },
  SET_START_LOADING(state: StateType): void {
    state.isLoading = true;
  },
};

export default mutations;
