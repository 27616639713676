type Every = string | number | null | string[];

const getApiQuery = (): Record<string, Every> => {
  const search = location.search.substring(1);
  let page = import.meta.env.VITE_APP_API_PAGE;
  let limit = import.meta.env.VITE_APP_API_LIMIT;
  let sortBy = null;
  let filterBy = null;

  if (search) {
    const pagination = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

    pagination?.page && (page = pagination.page);
    pagination?.limit && (limit = pagination.limit);
    pagination?.sortBy && (sortBy = pagination.sortBy);
    pagination?.filterBy && (filterBy = pagination.filterBy);
  }

  return { filterBy, limit, page, sortBy };
};

export default getApiQuery;
