import { Auth0 } from '@/auth';
import { translate } from '@/utils';

const SocialRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'NotFacebookLink',
    path: '/social/not-facebook-link/:scrappID?',
    props: {
      apiKey: 'not-facebook-link',
      description: translate('social.not-facebook-link.view.table.description'),
      title: translate('social.not-facebook-link.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'NotTwitterLink',
    path: '/social/not-twitter-link/:scrappID?',
    props: {
      apiKey: 'not-twitter-link',
      description: translate('social.not-twitter-link.view.table.description'),
      title: translate('social.not-twitter-link.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'NotInstagramLink',
    path: '/social/not-instagram-link/:scrappID?',
    props: {
      apiKey: 'not-instagram-link',
      description: translate('social.not-instagram-link.view.table.description'),
      title: translate('social.not-instagram-link.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'NotLinkedinLink',
    path: '/social/not-linkedin-link/:scrappID?',
    props: {
      apiKey: 'not-linkedin-link',
      description: translate('social.not-linkedin-link.view.table.description'),
      title: translate('social.not-linkedin-link.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'NotYoutubeLink',
    path: '/social/not-youtube-link/:scrappID?',
    props: {
      apiKey: 'not-youtube-link',
      description: translate('social.not-youtube-link.view.table.description'),
      title: translate('social.not-youtube-link.view.table.title'),
    },
  },
];

export default SocialRouter;
