import { Auth0 } from '@/auth';

const RedirectRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./all/all.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'RedirectsAll',
    path: '/redirects/all/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./all/all.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'RedirectsAll2',
    path: '/pages/3xx/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./not-2xx-redirect/not-2xx-redirect.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'Not200Redirect',
    path: '/redirects/not-2xx/:scrappID?',
  },
];

export default RedirectRouter;
