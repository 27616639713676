import { Auth0 } from '@/auth';

const UserRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./dictionary/dictionary.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
      projectName: 'Dictionary',
    },
    name: 'Dictionary',
    path: '/general/dictionary',
  },
];

export default UserRouter;
