import { Auth0 } from '@/auth';

const SuggesterRouter = [
  {
    beforeEnter: Auth0.routeGuard,

    component: () => import('./sitemap-validator/sitemap-validator.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'SitemapValidator',
    path: '/suggester/sitemap-validator/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,

    component: () => import('./sitemap-generator/sitemap-generator.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'SitemapGenerator',
    path: '/suggester/sitemap-generator/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./audit/audit.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'Audit',
    path: '/suggester/audit/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./image-compressor/image-compressor.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'ImageCompressor',
    path: '/suggester/image-compressor/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./access-log/access-log.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'AccessLog',
    path: '/suggester/access-log/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./error-log/error-log.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'ErrorLog',
    path: '/suggester/error-log/:scrappID?',
  },
];

export default SuggesterRouter;
