import Button from 'primevue/button';
import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({
  components: {
    PrimeButton: Button,
  },
})
export default class ButtonComponent extends Vue {
  @Prop({
    default: 'primary',
    validator: (value: string) => ['primary', 'secondary', 'tertiary', 'accent', 'black', 'white'].includes(value),
  })
  type: string;
  @Prop({ default: '', validator: (value: string) => ['lg', 'sm', ''].includes(value) }) size: string;
  @Prop({ required: true, type: String }) label: string;
  @Prop({ default: false }) isDisabled: boolean;
  @Prop({ default: 'button', validator: (value: string) => ['button', 'a', 'router-link'].includes(value) }) tag: string;
  @Prop({ default: null, type: String }) href: string;
  @Prop({ default: false }) haveLoader: boolean;
  @Prop({ type: String }) icon: string;
  @Prop({ default: null, validator: (value: string) => ['left', 'right'].includes(value) }) iconPos: string;
  @Prop({ default: '_self', type: String }) target: string;
  showLoader = false;
}
