import axios, { AxiosInstance, ResponseType } from 'axios';

import { getTokenSilently } from '@/auth';
import store from '@/store';
import translate from '@/utils/translate/translate.util';

interface AxiosInstanceOptions {
  withConfig?: boolean;
  useToken?: boolean;
  responseType?: ResponseType;
}

class AxiosService {
  private static async createBaseInstance(useToken: boolean, responseType: ResponseType): Promise<AxiosInstance> {
    const headers = {
      'Content-Type': 'application/json',
    } as Record<string, string>;

    if (useToken) headers['authorization'] = `Bearer ${await getTokenSilently()}`;

    return axios.create({
      baseURL: import.meta.env.VITE_APP_API_PATH,
      headers: headers,
      responseType: responseType,
    });
  }

  private static async createInstance(withConfig: boolean, useToken: boolean, responseType: ResponseType): Promise<AxiosInstance> {
    const AxiosInstance = await AxiosService.createBaseInstance(useToken, responseType);

    if (withConfig) {
      AxiosInstance.interceptors.response.use(
        (res) => {
          return res;
        },
        (err) => {
          let message = '';

          if (Array.isArray(err?.response?.data?.message)) message = err?.response?.data?.message[0]?.replaceAll(' ', '.')?.toLowerCase();
          else message = err?.response?.data?.message?.replaceAll(' ', '.')?.toLowerCase() ?? err?.response?.status;

          store.commit('SET_ALERT', {
            message: translate(message ? `application.${message}.error` : 'application.unknown.error'),
            type: 'error',
          });

          return Promise.reject(err);
        }
      );
    }

    return AxiosInstance;
  }

  static async getAxiosInstance(options: AxiosInstanceOptions = {} as AxiosInstanceOptions): Promise<AxiosInstance> {
    const { useToken = true, withConfig = true, responseType = 'json' } = options;
    return await AxiosService.createInstance(withConfig, useToken, responseType);
  }
}

export default AxiosService;
