import actions from './actions';
import { createStore } from 'vuex';
import getters from './getters';
import mutations from './mutations';
import { state } from './state';

const store = createStore({
  actions,
  getters,
  mutations,
  state,
});

store.subscribeAction({
  after: (_action, state) => (state.isLoading = false),
  before: (_action, state) => (state.isLoading = true),
});

export default store;
