import './bell.component.scss';

import { AxiosService } from '@/services';
import store from '@/store';
import { translate } from '@/utils';

interface NotificationItem {
  [key: string]: Record<string, string>[];
}

export default class BellComponent {
  static setInStore(scrappID: string, message: string): void {
    if (message.includes('sitemap.generator')) store.state.sitemapGeneratorReady[scrappID] = true;
    if (message.includes('sitemap.validator')) store.state.sitemapValidatorReady[scrappID] = true;
    if (message.includes('audit')) store.state.auditReady[scrappID] = true;
    if (message.includes('access.log')) store.state.accessLogReady[scrappID] = true;
    if (message.includes('error.log')) store.state.errorLogReady[scrappID] = true;
    if (message.includes('image.compressor')) store.state.imageCompressorReady[scrappID] = true;
  }

  static createBellItem(item: Record<string, string>): HTMLLIElement {
    const time = Math.floor((Date.now() - parseInt(item.createdAt)) / 1000 / 60);

    const itemList = document.createElement('li');
    itemList.classList.add('bell__item');

    const itemLink = document.createElement('a');
    const itemHeaderWrapper = document.createElement('div');
    const itemHeaderProjectName = document.createElement('p');
    const itemHeaderTime = document.createElement('p');
    const itemDescription = document.createElement('p');

    itemLink.classList.add('bell__item-link');
    itemHeaderWrapper.classList.add('bell__item-wrapper');
    itemHeaderProjectName.classList.add('bell__item-name');
    itemHeaderTime.classList.add('bell__item-time');
    itemDescription.classList.add('bell__item-description');

    itemLink.href = item?.redirectURL ?? '#';
    itemHeaderProjectName.innerText = item?.url?.replace(/^https?:\/\//, '')?.replace('/', '') ?? '';
    itemHeaderTime.innerText =
      time > 60 ? `${translate('bell.component.time.above.60.min.label')}` : `${time} ${translate('bell.component.time.min.ago.label')}`;

    itemDescription.innerText = translate(item.message);

    itemHeaderWrapper.appendChild(itemHeaderProjectName);
    itemHeaderWrapper.appendChild(itemHeaderTime);

    itemLink.appendChild(itemHeaderWrapper);
    itemLink.appendChild(itemDescription);

    itemList.appendChild(itemLink);

    return itemList;
  }

  static async getBellItems(): Promise<DocumentFragment | void> {
    const axios = await AxiosService.getAxiosInstance();
    const response = await axios.get('suggester/notifications');

    if (!Array.isArray(response.data)) return;

    const template = document.createDocumentFragment();

    response.data.forEach((item: NotificationItem) => {
      for (const key in item) {
        const elements = item[key];

        elements.forEach((element) => {
          BellComponent.setInStore(key, element.message);
          const bellItem = BellComponent.createBellItem(element);
          template.appendChild(bellItem);
        });
      }
    });

    return template;
  }
}
