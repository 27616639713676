import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import { reactive } from 'vue';
import { Options, Vue } from 'vue-property-decorator';

import { AxiosService } from '@/services';
import store from '@/store';

import { getPermissions, setPermissions, translate } from '@/utils';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import ButtonComponent from '../button/button.component.vue';
import HeaderComponent from '../header/header.component.vue';
import ConfirmDialog from 'primevue/confirmdialog';

@Options({
  components: { ButtonComponent, Calendar, Checkbox, Dialog, Dropdown, HeaderComponent, InputNumber, InputText, ConfirmDialog },
})
export default class CrawlerDialogComponent extends Vue {
  state = reactive({ filter: '', respectCanonical: false, schedulerFrequency: 'WEEKLY', skipUnfollow: false, url: '' });
  disabled = false;

  schedulerFrequencyState = [
    { label: translate('crawler.view.add.project.schedule.frequency.weekly.label'), value: 'WEEKLY' },
    { label: translate('crawler.view.add.project.schedule.frequency.monthly.label'), value: 'MONTHLY' },
    { label: translate('crawler.view.add.project.schedule.frequency.quarterly.label'), value: 'QUARTERLY' },
    { label: translate('crawler.view.add.project.schedule.frequency.semi.annually.label'), value: 'SEMI_ANNUALLY' },
  ];

  rules = {
    filter: {},
    respectCanonical: {},
    schedulerFrequency: {},
    sitesLimit: {},
    skipUnfollow: {},
    url: {
      required: helpers.withMessage(translate('form.field.message.required'), required),
    },
  };

  v$ = useVuelidate(this.rules, this.state);

  debounce(func: any, timeout = 2000) {
    let timer: number | undefined;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  async onSubmit() {
    this.disabled = true;
    const canAddScrapp = await this.canAddProject();
    if (!canAddScrapp) {
      this.$confirm.require({
        accept: async () => {
          this.$router.push('/user/plans');
        },
        acceptClass: 'p-warning',
        acceptLabel: 'Zwiększ plan',
        header: 'Limit stron osiągnięty',
        message: 'Aby przeskanować kolejną stronę zwiększ swój plan.',
        reject: () => null,
        rejectClass: 'p-cancel',
        rejectLabel: 'Anuluj',
      });
      return;
    }
    if (!this.state.url.includes('http')) this.state.url = `https://${this.state.url}`;
    if (this.state.url.substring(this.state.url.length - 1) !== '/') this.state.url = `${this.state.url}/`;
    const axios = await AxiosService.getAxiosInstance();
    await axios.put('/crawler/set-url', this.state);
    store.dispatch('setAlert', { message: translate('crawler.view.add.website.success.message'), type: 'success' });
    this.disabled = false;
    setTimeout(() => {
      window.location.replace('/recommendation');
    }, 100);
  }

  async canAddProject(): Promise<boolean> {
    let permission = getPermissions();

    if (!permission || !permission?.maxProjectCount) {
      const newAxios = await AxiosService.getAxiosInstance({ useToken: true, withConfig: false });
      const result = await newAxios.get('/operator/permission');
      setPermissions(result.data);
      permission = getPermissions();
    }

    const maxProject = permission?.maxProjectCount ?? 0;
    return store.getters['getCrawlerHistory'].total < maxProject;
  }
}
