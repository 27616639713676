import { Auth0 } from '@/auth';

const SiteRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./site.view.vue'),
    meta: { bellActivate: true, currentActive: 'DetailsComponent', requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'SiteView',
    path: '/site/:siteID',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./site.view.vue'),
    meta: { bellActivate: true, currentActive: 'DetailsComponent', requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'SiteDetailsView',
    path: '/site/details/:siteID',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./site.view.vue'),
    meta: { bellActivate: true, currentActive: 'ServerHeadersComponent', requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'SiteServerHeadersView',
    path: '/site/server-headers/:siteID',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./site.view.vue'),
    meta: { bellActivate: true, currentActive: 'PerformanceComponent', requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'SitePerformanceView',
    path: '/site/performance/:siteID',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./site.view.vue'),
    meta: { bellActivate: true, currentActive: 'W3CComponent', requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'SiteW3CView',
    path: '/site/w3c/:siteID',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./site.view.vue'),
    meta: { bellActivate: true, currentActive: 'ResourceComponent', requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'SiteResourceView',
    path: '/site/resource/:siteID',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./site.view.vue'),
    meta: { bellActivate: true, currentActive: 'AllHeaderComponent', requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'SiteHeadersView',
    path: '/site/headers/:siteID',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./site.view.vue'),
    meta: { bellActivate: true, currentActive: 'MetaTagsComponent', requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'SiteMetaView',
    path: '/site/meta/:siteID',
  },
];

export default SiteRouter;
