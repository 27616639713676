import { Auth0 } from '@/auth';

const IndexableRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./duplicate/duplicate.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'Duplicate',
    path: '/indexable/duplicate/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./paginated/paginated.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'Paginated',
    path: '/indexable/paginated/:scrappID?',
  },
];

export default IndexableRouter;
