import { Auth0 } from '@/auth';
import { translate } from '@/utils';

const UserRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./thanks-you/thanks-you.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true },
    name: 'UserThanksYou',
    path: '/user/thanks-you/:type',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./thanks-you-plugin/thanks-you.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true },
    name: 'UserThanksYou',
    path: '/plugin/thanks-you/:type',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./order-checkout-plugin/order-checkout.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true },
    name: 'PluginOrderCheckout',
    path: '/plugin/order-checkout/:type',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./order-checkout/order-checkout.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true },
    name: 'UserOrderCheckout',
    path: '/user/order-checkout/:type',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./payment/payment.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true },
    name: 'UserPayment',
    path: '/user/payment',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./settings/settings.view.vue'),
    meta: {
      bellActivate: true,
      currentActive: 'OverviewComponent',
      projectName: translate('setting.view.navbar.main.header.label'),
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'UserSettings',
    path: '/user/settings',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./settings/settings.view.vue'),
    meta: {
      bellActivate: true,
      currentActive: 'ProfileComponent',
      projectName: translate('setting.view.navbar.main.header.label'),
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'UserProfile',
    path: '/user/settings/profile',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./settings/settings.view.vue'),
    meta: {
      bellActivate: true,
      currentActive: 'NotificationsComponent',
      projectName: translate('setting.view.navbar.main.header.label'),
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'UserNotifications',
    path: '/user/settings/notifications',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./settings/settings.view.vue'),
    meta: {
      bellActivate: true,
      currentActive: 'IntegrationsComponent',
      projectName: translate('setting.view.navbar.main.header.label'),
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'UserIntegrations',
    path: '/user/settings/integrations',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./billing/billing.view.vue'),
    meta: { bellActivate: true, currentActive: 'OverviewComponent', requiresNavAndSidebar: true },
    name: 'UserBillingOverview',
    path: '/user/billing',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./billing/billing.view.vue'),
    meta: { bellActivate: true, currentActive: 'PaymentMethodsComponent', requiresNavAndSidebar: true },
    name: 'UserPaymentMethods',
    path: '/user/billing/payment-methods',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./billing/billing.view.vue'),
    meta: { bellActivate: true, currentActive: 'HistoryComponent', requiresNavAndSidebar: true },
    name: 'UserHistory',
    path: '/user/billing/history',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./plans/plans.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true },
    name: 'UserPlans',
    path: '/user/plans',
  },
];

export default UserRouter;
