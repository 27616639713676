import { Auth0 } from '@/auth';
import { translate } from '@/utils';

const W3CRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'W3CErrors',
    path: '/w3c/errors/:scrappID?',
    props: {
      apiKey: 'have-w3c-validation-errors',
      description: translate('wc3.errors.view.table.description'),
      title: translate('w3c.errors.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'W3CCriticalErrors',
    path: '/w3c/critical-errors/:scrappID?',
    props: {
      apiKey: 'have-critical-w3c-validation-errors',
      description: translate('wc3.critical.errors.view.table.description'),
      title: translate('w3c.critical.errors.view.table.title'),
    },
  },
];

export default W3CRouter;
