<template>
  <div class="knowledge-base__consultation-banner">
    <HeaderComponent tag="h4" :label="$translate('crawler.view.gold.banner.header.component')" :size="18" />
    <p>{{ $translate('crawler.view.gold.banner.description.component') }}</p>
    <ButtonComponent
      tag="a"
      type="black"
      target="_blank"
      :label="$translate('recomendation.help.component.second.btn.label')"
      href="https://calendly.com/marcin-gaworski/30min"
    />
    <img src="/public/icons/star-blue.svg" alt="" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ButtonComponent from '@/components/button/button.component.vue';
import HeaderComponent from '../header/header.component.vue';

@Options({
  components: { ButtonComponent, HeaderComponent },
})
export default class ConsultationBannerComponent extends Vue {}
</script>

<style lang="scss"></style>
