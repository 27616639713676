import { Auth0 } from '@/auth';
import { FilterHeaderDataType } from '@/components/data-table/data-table.model';
import { translate } from '@/utils';

const NotIndexableRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'NotFollow',
    path: '/not-indexable/not-follow/:scrappID?',
    props: {
      apiKey: 'not-follow',
      description: translate('not-indexable.not-follow.view.table.description'),
      title: translate('not-indexable.not-follow.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'Canonicallized',
    path: '/not-indexable/canonicallized/:scrappID?',
    props: {
      apiKey: 'canonicallized',
      description: translate('not-indexable.canonicallized.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
        {
          field: 'canonicalUrl',
          filterable: true,
          header: translate('data.table.component.header.canonicalUrl.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('not-indexable.canonicallized.view.table.title'),
    },
  },
];

export default NotIndexableRouter;
