import { LangData } from './translate.model';
import { default as en } from '../../locales/en.json';
// import { default as pl } from '../../locales/pl.json';

/**
 * generate translation by key
 */
const translate = (value: string, ...args: string[]): string => {
  const lang = localStorage.getItem('application_language');
  let translatedText = (en as LangData)[value];

  // if (lang === 'pl') translatedText = (pl as LangData)[value];
  // translatedText = (pl as LangData)[value];

  args.length > 0 ? (translatedText += ` ${args.join(' ')}`) : null;
  return `${translatedText && translatedText !== '' ? translatedText : value}`;
};

export default translate;
