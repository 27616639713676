import Menubar from 'primevue/menubar';
import OverlayPanel from 'primevue/overlaypanel';
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';

import { AxiosService } from '@/services';
import { getPermissions, translate, setPermissions } from '@/utils';

import BellComponent from './bell/bell.component';
import { NavigationItem } from './navigation.model';
import HeaderComponent from '../header/header.component.vue';
import BannerComponent from '../banner/banner.component.vue';
import store from '@/store';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';

import Bus from '@/Bus';
import { SetUrlEvent } from '@/views/main/crawler/crawler.model';
import CrawlerDialogComponent from '../crawler-dialog/crawler-dialog.component.vue';
import { Page } from '@/store/state.model';

@Options({
  components: { ConfirmDialog, HeaderComponent, Menubar, BannerComponent, Dialog, CrawlerDialogComponent, OverlayPanel },
  computed: {
    rawItems() {
      return store.getters['getCrawlerHistory']?.pages;
    },
    userEmail() {
      return store.getters['getOperator']?.email;
    },
  },
  inject: ['Auth'],
})
export default class NavigationComponent extends Vue {
  @Prop({ default: '' }) projectName: string;
  @Prop({ default: '' }) projectDate: string;

  items: NavigationItem[] = [];
  that = this;
  showAddProjectModal = false;
  projects: any[] = [];
  rawItems: Page[];

  mounted(): void {
    store.dispatch('getOperatorData');
    store.dispatch('getCrawlerHistory');
    this.setItems();
    this.getBellItems();
  }

  showOnboarding() {
    Bus.trigger('showOnboarding', true);
  }

  setItems() {
    this.items = [];
    if (this.$route.params.scrappID) {
      this.items.push(
        {
          class: 'dropdown',
          icon: 'pi pi-ellipsis-v',
          items: [
            {
              class: 'dropdown__logout',
              command: async (): Promise<void> => {
                this.$router.push('/general/dictionary');
              },
              label: translate('application.dropdown.dictionary'),
            },
          ],
        },
        {
          class: 'bell',
          icon: 'pi pi-bell',
          items: [
            { class: 'bell__header', label: translate('bell.component.list.title') },
            { label: translate('bell.component.not.have.notifications') },
          ],
        },
        {
          class: 'dropdown',
          icon: 'pi pi-sign-out',
          items: [
            {
              class: 'dropdown__logout',

              command: () => {
                this.logout();
              },
              label: translate('application.dropdown.logout'),
            },
          ],
        }
      );

      this.items[0].items?.push(
        {
          class: 'dropdown__logout',
          command: async (): Promise<void> => {
            this.showOnboarding();
          },
          label: translate('application.dropdown.show.onboarding'),
        },
        {
          class: 'dropdown__logout',
          command: async (): Promise<void> => {
            this.$confirm.require({
              accept: async () => {
                this.removeScrapp();
              },
              acceptClass: 'p-danger',
              acceptLabel: 'Delete',
              header: 'Delete project?',
              message: translate('crawler.view.action.delete.item'),
              reject: () => null,
              rejectClass: 'p-cancel',
              rejectLabel: 'Cancel',
            });
          },
          label: translate('application.dropdown.remove.scrapp'),
        }
      );
    }

    if (getPermissions()?.isAdmin && this.items[0].items) {
      this.items[0].items.unshift({
        label: 'Admin',
        to: '/admin',
      });
    }

    if (this.items[0].items && this.$route.params.scrappID) {
      this.items[0].items.unshift({
        label: 'My account',
        to: '/user/settings',
      });
    }

    this.getUserEmail();
  }

  logout() {
    this.Auth.logout({
      returnTo: `${import.meta.env.VITE_APP_WORDPRESS_LOGOUT}`,
    });
  }

  toggle(event: any) {
    this.$refs.op.toggle(event);
  }

  async removeScrapp() {
    store.state.isLoading = true;
    const axios = await AxiosService.getAxiosInstance();
    const response = await axios.delete(`/crawler/delete/${this.$route.params.scrappID}`);

    // if (response.status === 200) setTimeout(() => this.$emit('updateStore'), 1000);
    if (response.status === 200) {
      this.$emit('updateStore');
      setTimeout(() => {
        window.location.replace('/recommendation');
      }, 1000);
    }
    // this.$confirm.require({
    //   accept: async () => {
    //     store.state.isLoading = true;
    //     const axios = await AxiosService.getAxiosInstance();
    //     const response = await axios.delete(`/crawler/delete/${this.$route.params.scrappID}`);

    //     // if (response.status === 200) setTimeout(() => this.$emit('updateStore'), 1000);
    //     if (response.status === 200) {
    //       this.$emit('updateStore');
    //       setTimeout(() => {
    //         window.location.replace('/recommendation');
    //       }, 1000);
    //     }
    //   },
    //   acceptClass: 'p-danger',
    //   acceptLabel: 'Usuń',
    //   header: 'Usunąć projekt?',
    //   message: translate('crawler.view.action.delete.item'),
    //   reject: () => null,
    //   rejectClass: 'p-cancel',
    //   rejectLabel: 'Anuluj',
    // });
  }

  async getUserEmail(): Promise<void> {
    const axios = await await AxiosService.getAxiosInstance();
    const { data: userData } = await axios.get('operator/data');
    if (this.items[1].items) this.items[1].items.unshift({ class: 'dropdown__email', label: userData.email });
  }

  async handleSubmit(e: SetUrlEvent) {
    this.showAddProjectModal = false;
    const canAddScrapp = await this.canAddProject();
    if (!e.url.includes('http')) e.url = `http://${e.url}`;
    if (e.url.substring(e.url.length - 1) !== '/') e.url = `${e.url}/`;
    if (!canAddScrapp) {
      return;
    }

    const axios = await AxiosService.getAxiosInstance();
    await axios.put('/crawler/set-url', e);
    store.dispatch('setAlert', { message: translate('crawler.view.add.website.success.message'), type: 'success' });
    setTimeout(() => {
      location.reload();
    }, 2000);
  }

  async canAddProject(): Promise<boolean> {
    let permission = getPermissions();

    if (!permission || !permission?.maxProjectCount) {
      const newAxios = await AxiosService.getAxiosInstance({ useToken: true, withConfig: false });
      const result = await newAxios.get('/operator/permission');
      setPermissions(result.data);
      permission = getPermissions();
    }

    const maxProject = permission?.maxProjectCount ?? 0;
    return store.getters['getCrawlerHistory'].total < maxProject;
  }

  addProject() {
    this.showAddProjectModal = true;
  }

  @Watch('$route', { deep: true })
  async getBellItems(): Promise<void> {
    this.setItems();
    await this.$nextTick();

    const wrapper = document.querySelector('.bell ul') as HTMLUListElement;
    const template = await BellComponent.getBellItems();

    if (template && wrapper) {
      wrapper.innerHTML = `<li class="p-menuitem bell__header"></span><span class="p-menuitem-text">${translate(
        'bell.component.list.title'
      )}</span></li>`;

      wrapper.appendChild(template);
    }
  }

  @Watch('rawItems')
  mapItems() {
    if (!this.rawItems?.length) return this.$router.replace('/empty-crawler-list');
    this.projects = [];

    this.rawItems.forEach((item) => {
      const title = item.url.link.replace('https:', '').replace('http:', '').replaceAll('/', '');
      const symbol = title.charAt(0).toLocaleUpperCase();
      this.projects.push({
        attributes: { target: '_self' },
        external: true,
        href: `/recommendation/${item.scrappID}`,
        icon: { text: symbol },
        title,
      });
    });
  }

  async setLanguage(language: string) {
    localStorage.setItem('application_language', language);
    location.reload();
  }
}
