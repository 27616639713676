import { ActionContext } from 'vuex';

import { AxiosService } from '@/services';
import { RespondData } from '@/services/axios/axios.model';
import { getApiQuery } from '@/utils';

import { state } from './state';
import { Alert } from './state.model';

const actions = {
  cleanStore<S, R>(context: ActionContext<S, R>): void {
    context.commit('CLEAN_STORE');
  },
  async getAnotherData<S, R>(context: ActionContext<S, R>, type: string): Promise<void> {
    const { page, limit, sortBy, filterBy } = getApiQuery();
    const axios = await AxiosService.getAxiosInstance();
    const respond = await axios.get<RespondData>(
      `data/another-data/${type}/${state.scrappID}?page=${page}&limit=${limit}&sortBy=${sortBy}&filterBy=${filterBy}`
    );

    context.commit('SET_ANOTHER_DATA', respond.data.data);
  },
  async getCrawlerHistory<S, R>(context: ActionContext<S, R>): Promise<void> {
    const { page, limit, sortBy, filterBy } = getApiQuery();
    const axios = await AxiosService.getAxiosInstance();
    const respond = await axios.get<RespondData>(`crawler/history?page=${page}&limit=${limit}&sortBy=${sortBy}&filterBy=${filterBy}`);

    context.commit('SET_CRAWLER_HISTORY', respond.data.data);
  },
  async getDashboard<S, R>(context: ActionContext<S, R>): Promise<void> {
    const scrappID = state.scrappID;
    const axios = await AxiosService.getAxiosInstance();
    const respond = await axios.get(`data/dashboard/${scrappID}`);

    context.commit('SET_DASHBOARD', respond.data.data);
  },
  async getDetailsPage<S, R>(context: ActionContext<S, R>): Promise<void> {
    const axios = await AxiosService.getAxiosInstance();
    const respond = await axios.get<RespondData>(`data/page-details/${state.siteID}`);

    context.commit('SET_DETAILS_PAGE', respond.data);
  },
  async getImages<S, R>(context: ActionContext<S, R>, key: string): Promise<void> {
    const { page, limit, sortBy, filterBy } = getApiQuery();
    const axios = await AxiosService.getAxiosInstance();
    const respond = await axios.get<RespondData>(
      `data/images/${key}/${state.scrappID}?page=${page}&limit=${limit}&sortBy=${sortBy}&filterBy=${filterBy}`
    );

    context.commit('SET_IMAGES', respond.data.data);
  },
  async getIssue<S, R>(context: ActionContext<S, R>): Promise<void> {
    const scrappID = state.scrappID;
    const axios = await AxiosService.getAxiosInstance();
    const respond = await axios.get(`data/issues/long/${scrappID}`);

    context.commit('SET_ISSUE', respond.data.data.issue);
    context.commit('SET_RECOMENDATION_COMPARE', respond.data.data.compare);
  },
  async getLogs<S, R>(context: ActionContext<S, R>, key: string): Promise<void> {
    const { page, limit, sortBy, filterBy } = getApiQuery();
    const axios = await AxiosService.getAxiosInstance();
    const respond = await axios.get<RespondData>(
      `suggester/logs/${state.scrappID}/${key}?page=${page}&limit=${limit}&sortBy=${sortBy}&filterBy=${filterBy}`
    );

    context.commit('SET_LOGS', respond.data.data);
  },
  async getOperatorData<S, R>(context: ActionContext<S, R>): Promise<void> {
    const axios = await AxiosService.getAxiosInstance();
    const respond = await axios.get('operator/data');
    context.commit('SET_OPERATOR_DATA', respond.data);
  },
  async getPages<S, R>(context: ActionContext<S, R>, key: string): Promise<void> {
    if (!state?.scrappID || !key) return context.commit('SET_PAGES', {});
    const { page, limit, sortBy, filterBy } = getApiQuery();
    const axios = await AxiosService.getAxiosInstance();
    const respond = await axios.get<RespondData>(
      `data/pages/${key}/${state.scrappID}?page=${page}&limit=${limit}&sortBy=${sortBy}&filterBy=${filterBy}`
    );
    context.commit('SET_PAGES', respond.data.data);
  },
  async getRecomendationCompare<S, R>(context: ActionContext<S, R>, { url }: { url: string }): Promise<void> {
    const scrappID = state.scrappID;
    const axios = await AxiosService.getAxiosInstance();
    const respond = await axios.get(`compare/recomendation/${encodeURIComponent(url)}/${scrappID}`);

    context.commit('SET_RECOMENDATION_COMPARE', respond.data);
  },
  async getSearch<S, R>(context: ActionContext<S, R>, search: string): Promise<void> {
    const axios = await AxiosService.getAxiosInstance();
    const respond = await axios.get<RespondData>(`crawler/search?search=${search}`);

    context.commit('SET_SEARCH', respond.data.data);
  },
  async getSitemap<S, R>(context: ActionContext<S, R>): Promise<void> {
    const { page, limit, sortBy, filterBy } = getApiQuery();
    const axios = await AxiosService.getAxiosInstance();
    const respond = await axios.get<RespondData>(
      `suggester/sitemap/${state.scrappID}?page=${page}&limit=${limit}&sortBy=${sortBy}&filterBy=${filterBy}`
    );

    context.commit('SET_SITEMAP', respond.data.data);
  },
  setAlert<S, R>(context: ActionContext<S, R>, alert: Alert): void {
    context.commit('SET_ALERT', alert);
  },
  setScrappID<S, R>(context: ActionContext<S, R>, id: string): void {
    localStorage.setItem(import.meta.env.VITE_APP_SCRAPP_ID ?? '', id);
    context.commit('SET_SCRAPP_ID', id);
  },
  setSiteID<S, R>(context: ActionContext<S, R>, id: string): void {
    localStorage.setItem(import.meta.env.VITE_APP_SITE_ID ?? '', id);
    context.commit('SET_SITE_ID', id);
  },
};

export default actions;
