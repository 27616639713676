import store from '@/store';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  components: {},
})
export default class BannerComponent extends Vue {
  @Prop({ default: true }) type: string;
  @Prop({ default: null, required: false }) showBannerForce: boolean | null;

  label = '';
  btnLabel = '';
  btnHref = '';
  showBanner = false;
  style = 'normal';
  created() {
    const operator = store.getters['getOperator'];
    if (this.showBannerForce === null && operator?.accountType === 'FREE') {
      this.showBanner = true;
    }
    if (this.showBannerForce !== null) {
      this.showBanner = this.showBannerForce;
    }
    switch (this.type) {
      case 'navigation':
        this.label = `Wersja próbna pozwala podejrzeć jedynie część rekomendacji. Zwiększ pakiet, aby zobaczyć je wszystkie.`;
        this.btnLabel = 'Zwiększ pakiet';
        this.btnHref = '/user/plans';
        this.style = 'warning';
        break;
      case 'recomendations':
        this.label = `Mamy dla Ciebie więcej rekomendacji. <a href="/user/plans">Zwiększ pakiet</a> aby zobaczyć wszystkie.`;
        break;
      case 'single_reko':
        this.label = `Poniżej widzisz jedynie 10 dotyczących podstron. <a href="/user/plans">Zwiększ pakiet</a>, aby zobaczyc pozostałe.`;
        break;

      default:
        break;
    }
  }

  timeLeft() {
    // const operator = store.getters['getOperator'];
    // const time = new Date(operator.created_at);
    // const diff = (Date.now() - time) / 1000 / 60;
    // this.timeLeft = diff;
    // if (operator.accountType === 'FREE') {
    //   // this.showBanner = true;
    // }
    return 5;
  }
}
