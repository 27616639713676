import { StateType } from './state.model';

const state: StateType = {
  accessLogReady: {},
  alert: undefined,
  anotherData: undefined,
  auditReady: {},
  dashboard: undefined,
  errorLogReady: {},
  history: undefined,
  imageCompressorReady: {},
  images: undefined,
  isLoading: false,
  issue: undefined,
  logs: undefined,
  page: undefined,
  pages: undefined,
  progress: undefined,
  recomendationCompare: {},
  scrappID: undefined,
  searchResult: undefined,
  siteID: undefined,
  sitemap: undefined,
  sitemapGeneratorReady: {},
  sitemapValidatorReady: {},
};

export { state };
