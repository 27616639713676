import Button from 'primevue/button';
import { Options, Vue, Watch } from 'vue-property-decorator';
import { SidebarMenu } from 'vue-sidebar-menu';
import store from '@/store';
import { Page } from '../../store/state.model';
import Dialog from 'primevue/dialog';
import CrawlerDialogComponent from '../crawler-dialog/crawler-dialog.component.vue';
import { getPermissions, translate, setPermissions } from '@/utils';
import { AxiosService } from '@/services';
import ButtonIconComponent from '../button-icon/button-icon.component.vue';

interface SetUrlEvent {
  url: string;
  filter: string;
  respectCanonical: boolean;
  skipUnfollow: boolean;
}

@Options({
  beforeRouteUpdate(_to, _from, next) {
    this.updateActive();
    next();
  },
  components: { Button, ButtonIconComponent, CrawlerDialogComponent, Dialog, SidebarMenu },
  computed: {
    rawItems() {
      return store.getters['getCrawlerHistory']?.pages;
    },
    userEmail() {
      return store.getters['getOperator']?.email;
    },
  },
  inject: ['Auth'],
})
export default class SidebarComponent extends Vue {
  items: any[] = [];
  rawItems: Page[];
  showAddProjectModal = false;

  mounted() {
    store.dispatch('getOperatorData');
    store.dispatch('getCrawlerHistory');
  }

  @Watch('rawItems')
  mapItems() {
    if (!this.rawItems?.length) return this.$router.replace('/empty-crawler-list');
    this.items = [];

    this.rawItems.forEach((item) => {
      const title = item.url.link.replace('https:', '').replace('http:', '').replaceAll('/', '');
      const symbol = title.charAt(0).toLocaleUpperCase();
      this.items.push({
        attributes: { target: '_self' },
        external: true,
        href: `/recommendation/${item.scrappID}`,
        icon: { text: symbol },
        title,
      });
    });

    this.items.push({
      class: 'sidebar__new-project',
      href: `#add-new-project`,
      icon: { text: '+' },
      title: '',
    });
  }

  onItemClick(event: any, item: any) {
    if (item.href === `#add-new-project`) this.showAddProjectModal = true;
  }

  async handleSubmit(e: SetUrlEvent) {
    this.showAddProjectModal = false;
    const canAddScrapp = await this.canAddProject();
    if (!e.url.includes('http')) e.url = `http://${e.url}`;
    if (e.url.substring(e.url.length - 1) !== '/') e.url = `${e.url}/`;
    if (!canAddScrapp) {
      return;
    }

    const axios = await AxiosService.getAxiosInstance();
    await axios.put('/crawler/set-url', e);
    store.dispatch('setAlert', { message: translate('crawler.view.add.website.success.message'), type: 'success' });
    setTimeout(() => {
      location.reload();
    }, 2000);
  }

  async canAddProject(): Promise<boolean> {
    let permission = getPermissions();

    if (!permission || !permission?.maxProjectCount) {
      const newAxios = await AxiosService.getAxiosInstance({ useToken: true, withConfig: false });
      const result = await newAxios.get('/operator/permission');
      setPermissions(result.data);
      permission = getPermissions();
    }

    const maxProject = permission?.maxProjectCount ?? 0;
    return store.getters['getCrawlerHistory'].total < maxProject;
  }

  logout() {
    this.Auth.logout({
      returnTo: `${import.meta.env.VITE_APP_WORDPRESS_LOGOUT}`,
    });
  }
}
