export enum FilterHeaderDataType {
  Text = 'Text',
  Select = 'Select',
  Range = 'Range',
  Date = 'Date',
  Number = 'Number',
}

export interface DataTableFilters {
  field: string;
  value: string | number | string[] | number[] | boolean;
  type: keyof typeof FilterHeaderDataType;
}

export interface DataTableHeader {
  field: string;
  header: string;
  type?: keyof typeof FilterHeaderDataType;
  sortable?: boolean;
  filterable?: boolean;
  selectable?: string[];
}

export interface DataTableSort {
  sortField: string;
  sortOrder: 1 | -1;
}

export interface DataTablePagination {
  page: number;
  rows: number;
}

export interface DataTableApiQuery {
  filterBy?: string;
  pagination?: string;
  sortBy?: string;
}
