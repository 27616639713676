import { AnotherData, Logs, OperatorResponse, Page, PageResponse, Sitemap, StateType } from './state.model';

type Maybe<T> = T | undefined;

const getters = {
  getAnotherData(state: StateType): Maybe<AnotherData> {
    return state.anotherData;
  },
  getCrawlerHistory(state: StateType): Maybe<PageResponse> {
    return state.history;
  },
  getDetailsPage(state: StateType): Maybe<Page> {
    return state.page;
  },
  getHomepage(state: StateType): Maybe<Page> {
    return state.dashboard?.page;
  },
  getImages(state: StateType): Maybe<ImageData[]> {
    return state.images;
  },
  getIssue(state: StateType): Maybe<Record<string, number>> {
    return state?.issue ?? state.dashboard?.issues;
  },
  getLoadingStatus(state: StateType): boolean {
    return state.isLoading;
  },
  getLogs(state: StateType): Maybe<Logs> {
    return state.logs;
  },
  getOperator(state: StateType): Maybe<OperatorResponse> {
    return state.operator;
  },
  getPage(state: StateType): Maybe<Page> {
    return state.page;
  },
  getPageCount(state: StateType): Maybe<number> {
    return state.dashboard?.pageCount;
  },
  getPages(state: StateType): Maybe<PageResponse> {
    return state.pages;
  },
  getRecomendationCompare(state: StateType): Maybe<Record<string, number>> {
    return state?.recomendationCompare;
  },
  getScrappID(state: StateType): string | null {
    return state?.scrappID || localStorage.getItem(import.meta.env.VITE_APP_SCRAPP_ID ?? '');
  },
  getSearchResult(state: StateType): Record<string, string | number>[] {
    return state.searchResult
      ? state.searchResult?.pages.map(({ scrappID, url, createdAt }) => ({ createdAt, scrappID, text: url.link, value: url.link }))
      : [];
  },
  getSitemap(state: StateType): Maybe<Sitemap> {
    return state.sitemap;
  },
  getStatusCodes(state: StateType): Maybe<Record<number, number>[]> {
    return state.dashboard?.statusCodes ?? [];
  },
  getVisibility(state: StateType): Maybe<unknown> {
    return state.dashboard?.visibility?.data;
  },
};

export default getters;
