import { Auth0 } from '@/auth';
import { FilterHeaderDataType } from '@/components/data-table/data-table.model';
import { translate } from '@/utils';

const DescriptionsRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'MissingDescriptions',
    path: '/descriptions/missing/:scrappID?',
    props: {
      apiKey: 'have-not-description',
      description: translate('descriptions.missing.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('descriptions.missing.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'NotEmojiDescriptions',
    path: '/descriptions/not-emoji/:scrappID?',
    props: {
      apiKey: 'not-emoji-in-description',
      description: translate('descriptions.not-emoji.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('descriptions.not-emoji.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./duplicate/duplicate.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'DuplicateDescriptions',
    path: '/descriptions/duplicate/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./width/width.view.vue'),
    meta: { apiKey: 'description-70-characters', bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'WidthDescription70',
    path: '/descriptions/width/70-characters/:scrappID?',
    props: {
      description: translate('descriptions.width.70.view.table.description'),
      title: translate('navigation.item.descriptions.70-characters.label'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./width/width.view.vue'),
    meta: { apiKey: 'description-320-characters', bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'WidthDescription320',
    path: '/descriptions/width/320-characters/:scrappID?',
    props: {
      description: translate('descriptions.width.320.view.table.description'),
      title: translate('navigation.item.descriptions.320-characters.label'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./width/width.view.vue'),
    meta: { apiKey: 'have-description-below-120-characters', bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'WidthDescription120',
    path: '/descriptions/width/120-characters/:scrappID?',
    props: {
      description: translate('descriptions.width.120.view.table.description'),
      title: translate('navigation.item.descriptions.120-characters.label'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./width/width.view.vue'),
    meta: { apiKey: 'have-description-above-155-characters', bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'WidthDescription155',
    path: '/descriptions/width/155-characters/:scrappID?',
    props: {
      description: translate('descriptions.width.155.view.table.description'),
      title: translate('navigation.item.descriptions.155-characters.label'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./width/width.view.vue'),
    meta: { apiKey: 'description-400-px', bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'WidthDescription400',
    path: '/descriptions/width/400-px/:scrappID?',
    props: {
      description: translate('descriptions.width.400.view.table.description'),
      title: translate('navigation.item.descriptions.400-px.label'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./width/width.view.vue'),
    meta: { apiKey: 'description-1866-px', bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'WidthDescription1866',
    path: '/descriptions/width/1866-px/:scrappID?',
    props: {
      description: translate('descriptions.width.1866.view.table.description'),
      title: translate('navigation.item.descriptions.1866-px.label'),
    },
  },
];

export default DescriptionsRouter;
