import { Auth0 } from '@/auth';

const AdminRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./users/users.view.vue'),
    meta: { isAdminPanel: true, requiresNavAndSidebar: true },
    name: 'AdminUsers',
    path: '/admin',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./user/user.view.vue'),
    meta: { isAdminPanel: true, requiresNavAndSidebar: true },
    name: 'AdminUser',
    path: '/admin/user/:id',
  },
];

export default AdminRouter;
