import { RouteLocationNormalized } from 'vue-router';

import { AxiosService } from '@/services';
import store from '@/store';
import { PageResponse } from '@/store/state.model';
import { getApiQuery, getPermissions } from '@/utils';

export const optionalScrappIDMiddleware = async (to: RouteLocationNormalized): Promise<void> => {
  let scrappID = '';

  if (to.params.scrappID) return;
  if (store.state.scrappID) scrappID = store.state.scrappID;

  if (!scrappID) {
    const { page, limit, sortBy, filterBy } = getApiQuery();
    const axios = await AxiosService.getAxiosInstance();
    const respond = await axios.get(`crawler/history?page=${page}&limit=${limit}&sortBy=${sortBy}&filterBy=${filterBy}`);
    const data = respond.data.data as PageResponse;

    scrappID = data?.pages[0]?.scrappID;
    if (!scrappID) return window.location.replace(`${location.origin}/empty-crawler-list`);
    to.params.scrappID = scrappID;
  }

  if (to.fullPath.slice(-1) === '/') window.location.replace(`${location.origin}${to.fullPath}${scrappID}`);
  else window.location.replace(`${location.origin}${to.fullPath}/${scrappID}`);
};

export const adminPanelMiddleware = async (): Promise<void> => {
  if (!getPermissions()?.isAdmin) {
    throw Error('You are not authorized to access this page');
  }
};
