import { App, computed, Plugin, reactive, watchEffect } from 'vue';
import { NavigationGuard, NavigationGuardWithThis } from 'vue-router';

import createAuth0Client, {
  Auth0Client,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  RedirectLoginOptions,
  User,
} from '@auth0/auth0-spa-js';

let client: Auth0Client;

interface Auth0PluginState {
  loading: boolean;
  isAuthenticated: boolean;
  user: User | undefined;
  popupOpen: boolean;
  error: unknown;
}

export const state = reactive<Auth0PluginState>({
  error: null,
  isAuthenticated: false,
  loading: true,
  popupOpen: false,
  user: {},
});

async function handleRedirectCallback() {
  state.loading = true;

  try {
    await client.handleRedirectCallback();
    state.user = await client.getUser();
    state.isAuthenticated = true;
  } catch (e) {
    state.error = e;
  } finally {
    state.loading = false;
  }
}

function singupWithRedirect(o: RedirectLoginOptions = {}) {
  o.screen_hint = 'signup';
  return client.loginWithRedirect(o);
}

function loginWithPopup(o: RedirectLoginOptions) {
  return client.loginWithPopup(o);
}

function loginWithRedirect(o: RedirectLoginOptions) {
  if (location.href.includes('nologin=register')) o.screen_hint = 'signup';
  return client.loginWithRedirect(o);
}

function getIdTokenClaims(o: GetIdTokenClaimsOptions) {
  return client.getIdTokenClaims(o);
}

export async function getTokenSilently(o: GetTokenSilentlyOptions = {}): Promise<string> {
  return await client.getTokenSilently(o);
}

export function getAuth0User(): User | undefined {
  return state.user;
}

function getTokenWithPopup(o: GetTokenWithPopupOptions) {
  return client.getTokenWithPopup(o);
}

function logout(o: LogoutOptions) {
  return client.logout(o);
}

const authPlugin = {
  getIdTokenClaims,
  getTokenSilently,
  getTokenWithPopup,
  handleRedirectCallback,
  isAuthenticated: computed(() => state.isAuthenticated),
  loading: computed(() => state.loading),
  loginWithPopup,
  loginWithRedirect,
  logout,
  singupWithRedirect,
  user: computed(() => state.user),
};

const routeGuard: NavigationGuard = (to, from, next) => {
  const { isAuthenticated, loading, loginWithRedirect } = authPlugin;

  const verify = async () => {
    if (isAuthenticated.value) {
      return next();
    }

    await loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  if (!loading.value) {
    return verify();
  }

  watchEffect(() => {
    if (!loading.value) {
      return verify();
    }
  });
};

interface Auth0PluginOptions {
  domain: string;
  clientId: string;
  audience: string;
  redirectUri: string;

  onRedirectCallback(appState: unknown): void;
}

async function init(options: Auth0PluginOptions): Promise<Plugin> {
  client = await createAuth0Client({
    audience: options.audience,
    cacheLocation: 'localstorage',
    client_id: options.clientId,
    domain: options.domain,
    redirect_uri: options.redirectUri,
    useRefreshTokens: true,
  });

  try {
    if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
      const { appState } = await client.handleRedirectCallback();
      options.onRedirectCallback(appState);
    }
  } catch (e) {
    state.error = e;
  } finally {
    state.isAuthenticated = await client.isAuthenticated();
    state.user = await client.getUser();
    state.loading = false;
  }

  return {
    install: (app: App) => {
      app.provide('Auth', authPlugin);
    },
  };
}

interface Auth0Plugin {
  init(options: Auth0PluginOptions): Promise<Plugin>;
  routeGuard: NavigationGuardWithThis<undefined>;
}

export const Auth0: Auth0Plugin = {
  init,
  routeGuard,
};
