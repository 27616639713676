interface Permission {
  maxProjectCount: number;
  maxUrlPerProject: number;
  accountType: string;
  accountActive: boolean;
  isAdmin: boolean;
}

const getPermissions = (): Permission => JSON.parse(localStorage?.getItem(import.meta.env.VITE_APP_PERMISSION_NAME) ?? '{}');
const setPermissions = (data: Permission): void => localStorage?.setItem(import.meta.env.VITE_APP_PERMISSION_NAME, JSON.stringify(data));

export { getPermissions, setPermissions };
