import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({
  components: {},
})
export default class ButtonIconComponent extends Vue {
  @Prop({ required: true, type: String }) src: string;
  @Prop({ default: 'button', validator: (value: string) => ['button', 'router-link'].includes(value) }) tag: string;
  @Prop({ default: null, type: String }) href: string;
}
