<template>
  <div>
    <template v-if="$route.meta.requiresNavAndSidebar">
      <NavigationComponent v-if="projectName" :project-name="projectName" :project-date="projectCreatedAt" />
    </template>

    <Toast position="top-right" />

    <div ref="mainWrapper" :class="{ 'crawler-container': true, 'no-naviagtion': !isRequiresSidebar, auth0: isAuth0Loading }">
      <router-view :key="$route.name" />
    </div>

    <FooterComponent :class="{ short: isRequiresSidebar }" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import 'primeflex/primeflex.css';
import { mapState } from 'vuex';
import { Watch } from 'vue-property-decorator';
import NavigationComponent from '@/components/navigation/navigation.component.vue';
import SidebarComponent from '@/components/sidebar/sidebar.component.vue';
import Toast from 'primevue/toast';
import { Alert, Page } from './store/state.model';
import { translate } from './utils';

import FooterComponent from '@/components/footer/footer.component.vue';
import store from './store';

@Options({
  components: { FooterComponent, NavigationComponent, SidebarComponent, Toast },
  computed: {
    ...mapState(['alert']),
    projects() {
      return store.getters['getCrawlerHistory']?.pages;
    },
  },
  inject: ['Auth'],
})
export default class App extends Vue {
  projects: Page[];

  mounted() {
    store.dispatch('getOperatorData');
    store.dispatch('getCrawlerHistory');
  }
  updated(): void {
    let url = this.$route.fullPath;
    if (this.$route.matched[0].path.includes(':scrappID')) url = this.$route.matched[0].path.replace('/:scrappID', '').replace('?', '');
    if (this.$route.matched[0].path.includes(':siteID')) url = this.$route.matched[0].path.replace('/:siteID', '');

    import.meta.env.VITE_APP_USE_GTM && this.$gtm.trackView(this.$route.name as string, url);
  }

  get isRequiresSidebar(): boolean {
    return false;
    // return this.$route.meta.requiresSidebar;
    // if (!!this.projects && this.projects.length > 1) {
    //   return this.$route.meta.requiresSidebar;
    // }
    // return false;
  }

  get isAuth0Loading(): boolean {
    return this.$route.meta.isAuth0Loading as boolean;
  }

  get projectName(): string {
    if (this.$route.meta.projectName) return this.$route.meta.projectName as string;
    const scrappID = this.$route.params.scrappID;
    if (!scrappID) return '';

    const project = this.projects?.find((project) => project.scrappID === scrappID.replace('#dev', '')) ?? ({} as Page);
    return project?.url?.link?.replace('https:', '')?.replace('http:', '')?.replaceAll('/', '') ?? '';
  }

  get projectCreatedAt(): string {
    const scrappID = this.$route.params.scrappID;
    if (!scrappID) return '';

    const project = this.projects?.find((project) => project.scrappID === scrappID.replace('#dev', '')) ?? ({} as Page);

    let date;
    if (project?.updatedAt) {
      date = project?.updatedAt;
    } else if (project?.createdAt) {
      date = project?.createdAt;
    } else {
      date = '';
    }

    return date ? new Date(date * 1000).toLocaleString('PL-pl') : '';
  }

  onToggleCollapse(): void {
    (this.$refs.mainWrapper as Element).classList.toggle('active');
  }

  @Watch('alert', { deep: true })
  onPropertyChanged(newAlert: Alert): void {
    const summary = newAlert.type === 'error' ? translate('application.toast.error.label') : translate('application.toast.success.label');
    this.$toast.add({ detail: newAlert.message, life: 5000, severity: newAlert.type, summary });
  }
}
</script>

<style lang="scss">
@use './assets/scss/base/base-style.scss';
@use './assets/scss/helpers/helpers.scss';
@use './assets/scss/helpers/overlay.scss';
</style>
