import { Auth0 } from '@/auth';
import { FilterHeaderDataType } from '@/components/data-table/data-table.model';
import { translate } from '@/utils';

const UrlRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'TimeoutURL',
    path: '/url/timeout/:scrappID?',
    props: {
      apiKey: 'have-timeout-url',
      description: translate('url.timeout.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
        {
          field: 'statusCode',
          filterable: true,
          header: translate('data.table.component.header.status.code.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('url.timeout.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'MalformedURL',
    path: '/url/malformed/:scrappID?',
    props: {
      apiKey: 'have-malformed-url',
      description: translate('url.malformed.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
        {
          field: 'statusCode',
          filterable: true,
          header: translate('data.table.component.header.status.code.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('url.malformed.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'LongURL',
    path: '/url/long-url/:scrappID?',
    props: {
      apiKey: 'have-too-long-url',
      description: translate('url.long-url.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
        {
          field: 'statusCode',
          filterable: true,
          header: translate('data.table.component.header.status.code.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('url.long-url.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'ParamsURL',
    path: '/url/params/:scrappID?',
    props: {
      apiKey: 'have-params-url',
      description: translate('url.params.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
        {
          field: 'statusCode',
          filterable: true,
          header: translate('data.table.component.header.status.code.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('url.params.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'AsciURL',
    path: '/url/asci/:scrappID?',
    props: {
      apiKey: 'have-ascii-url',
      description: translate('url.ascii.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
        {
          field: 'statusCode',
          filterable: true,
          header: translate('data.table.component.header.status.code.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('url.ascii.view.table.title'),
    },
  },
];

export default UrlRouter;
