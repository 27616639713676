import './polyfill';

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import sanitizeHtml from 'sanitize-html';
import { createApp } from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import { createGtm } from '@gtm-support/vue-gtm';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';

import App from './App.vue';
import { Auth0 } from './auth';
import router from './router';
import store from './store';
import { translate } from './utils';
import HeaderComponent from './components/header/header.component.vue';
import ButtonIconComponent from './components/button-icon/button-icon.component.vue';
import ButtonComponent from './components/button/button.component.vue';
import ConsultationBannerComponent from './components/consultation-banner/consultation-banner.component.vue';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';

(async function () {
  const AuthPlugin = await Auth0.init({
    audience: import.meta.env.VITE_APP_AUTH0_AUDIENCE,
    clientId: import.meta.env.VITE_APP_AUTH0_CLIENT_KEY,
    domain: import.meta.env.VITE_APP_AUTH0_DOMAIN,
    onRedirectCallback: (appState: { targetUrl: string }) => {
      router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
    },
    redirectUri: `${window.location.origin}/check-user?site=${
      location.pathname === '/' || location.pathname.includes('check-user') ? '/recommendation' : location.pathname
    }`,
  });

  const app = createApp(App)
    .use(AuthPlugin)
    .use(store)
    .use(router)
    .use(PrimeVue)
    .use(ToastService)
    .use(ConfirmationService)
    .use(VueReCaptcha, { siteKey: import.meta.env.VITE_CAPTCHA_SITE_KEY });

  app.directive('tooltip', Tooltip);

  app.config.globalProperties.$translate = translate;
  app.config.globalProperties.$sanitize = sanitizeHtml;
  app.config.globalProperties.$domain = import.meta.env.VITE_WORDPRESS_DOMAIN;

  app.component('HeaderComponent', HeaderComponent);
  app.component('ButtonIconComponent', ButtonIconComponent);
  app.component('ButtonComponent', ButtonComponent);
  app.component('ConsultationBannerComponent', ConsultationBannerComponent);
  app.component('TabPanel', TabPanel);
  app.component('TabView', TabView);

  import.meta.env.VITE_APP_USE_GTM &&
    app.use(
      createGtm({
        debug: true,
        defer: false,
        enabled: true,
        id: 'GTM-K37TPGC',
        loadScript: true,
        nonce: '2726c7f26c',
        trackOnNextTick: false,
        vueRouter: router,
      })
    );

  import.meta.env.VITE_APP_USE_SENTRY &&
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_APP_SENTRY_DNS,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['*'],
        }),
      ],
      logErrors: true,
      tracesSampleRate: 1.0,
    });

  app.mount('#app');
})();

//CSS translate

document.documentElement.style.setProperty(
  '--add-discount-code-plus',
  `'${translate('order.checkout.view.summary.add.discount.code.plus.label')}'`
);
document.documentElement.style.setProperty(
  '--add-discount-code-minus',
  `'${translate('order.checkout.view.summary.add.discount.code.minus.label')}'`
);
