import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { Auth0 } from '@/auth';
import store from '@/store';
import AdminRouter from '@/views/admin/admin.router';
import DescriptionsRouter from '@/views/descriptions/descriptions.router';
import HeadersRouter from '@/views/headers/headers.router';
import IframesRouter from '@/views/iframes/iframes.router';
import ImagesRouter from '@/views/images/images.router';
import IndexableRouter from '@/views/indexable/indexable.router';
import NotIndexableRouter from '@/views/not-indexable/not-indexable.router';
import PagesRouter from '@/views/pages/pages.router';
import RedirectRouter from '@/views/redirects/redirects.router';
import SiteRouter from '@/views/site/site.router';
import SocialRouter from '@/views/social/social.router';
import SuggesterRouter from '@/views/suggester/suggester.router';
import TitlesRouter from '@/views/titles/titles.router';
import UrlRouter from '@/views/url/url.router';
import UserRouter from '@/views/user/user.router';
import GenericRouter from '@/views/generic/generic.router';
import W3CRouter from '@/views/w3c/w3c.router';

import { adminPanelMiddleware, optionalScrappIDMiddleware } from './middleware';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    component: () => import('@/views/login/login.view.vue'),
    meta: { isAuth0Loading: true },
    name: 'Login',
    path: '/login',
  },

  {
    component: () => import('@/views/check-user/check-user.view.vue'),
    meta: { isAuth0Loading: true },
    name: 'CheckUser',
    path: '/check-user',
  },
  {
    component: () => import('@/views/register/register.view.vue'),
    meta: { isAuth0Loading: true },
    name: 'Register',
    path: '/register',
  },
  {
    component: () => import('@/views/affiliate/affiliate.view.vue'),
    name: 'Affiliate',
    path: '/affiliate/:gclid',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('@/views/main/crawler/crawler.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true },
    name: 'Crawler',
    path: '/crawler',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('@/views/main/dashboard/dashboard.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'Dashboard',
    path: '/dashboard/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('@/views/main/recomendation/recomendation.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'Recomendation',
    path: '/recommendation/:scrappID?',
  },
  {
    component: () => import('@/views/external-plugin/external-plugin.view.vue'),
    meta: { isAuth0Loading: true },
    name: 'ExternalPlugin',
    path: '/external-plugin',
  },
  {
    component: () => import('@/views/empty-crawler-list/empty-crawler-list.view.vue'),
    name: 'EmptyCrawlerList',
    path: '/empty-crawler-list',
  },
  {
    component: () => import('@/views/knowledge-base/knowledge-base.view.vue'),
    name: 'KnowledgeBase',
    path: '/knowledge-base',
  },
  ...AdminRouter,
  ...UserRouter,
  ...PagesRouter,
  ...SiteRouter,
  ...UrlRouter,
  ...RedirectRouter,
  ...HeadersRouter,
  ...IframesRouter,
  ...NotIndexableRouter,
  ...IndexableRouter,
  ...ImagesRouter,
  ...TitlesRouter,
  ...DescriptionsRouter,
  ...SuggesterRouter,
  ...W3CRouter,
  ...SocialRouter,
  ...GenericRouter,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.dispatch('cleanStore');

  if (to.matched.some((route) => route.path.includes('/:scrappID?'))) await optionalScrappIDMiddleware(to);
  if (to.meta?.isAdminPanel) await adminPanelMiddleware();

  return next();
});

export default router;
