import { Auth0 } from '@/auth';
import { FilterHeaderDataType } from '@/components/data-table/data-table.model';
import { translate } from '@/utils';

const HeadersRouter = [
  {
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'InvalidHeadersRepeatability',
    path: '/headers/invalid-repeatability/:scrappID?',
    props: {
      apiKey: 'have-invalid-h1-repeatability',
      beforeEnter: Auth0.routeGuard,
      description: translate('pages.headers.invalid-h1-repeatability.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('pages.headers.invalid-h1-repeatability.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'InvalidHierarchy',
    path: '/headers/invalid-hierarchy/:scrappID?',
    props: {
      apiKey: 'have-invalid-headers-hierarchy',
      description: translate('pages.headers.invalid-hierarchy.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('pages.headers.invalid-hierarchy.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'NotQuestionInHeaders',
    path: '/headers/not-question-in-headers/:scrappID?',
    props: {
      apiKey: 'not-question-in-headers',
      description: translate('pages.headers.not-question-in-headers.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('pages.headers.not-question-in-headers.table.title'),
    },
  },
];

export default HeadersRouter;
