import { Auth0 } from '@/auth';
import { FilterHeaderDataType } from '@/components/data-table/data-table.model';
import translate from '@/utils/translate/translate.util';

const TitlesRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'TitlesMissing',
    path: '/titles/missing/:scrappID?',
    props: {
      apiKey: 'have-not-title',
      description: translate('titles.missing.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('titles.missing.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./duplicate/duplicate.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'TitlesDuplicate',
    path: '/titles/duplicate/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./width/width.view.vue'),
    meta: { apiKey: 'title-200-px', bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'TitleWidth200',
    path: '/titles/width/200-px/:scrappID?',
    props: { description: translate('titles.width.200.view.table.description'), title: translate('navigation.item.titles.200-px.label') },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./width/width.view.vue'),
    meta: { apiKey: 'title-571-px', bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'TitleWidth571',
    path: '/titles/width/571-px/:scrappID?',
    props: { description: translate('titles.width.571.view.table.description'), title: translate('navigation.item.titles.571-px.label') },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./width/width.view.vue'),
    meta: { apiKey: 'have-title-below-50-characters', bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'TitleWidth50',
    path: '/titles/width/50-characters/:scrappID?',
    props: {
      description: translate('titles.width.50.view.table.description'),
      title: translate('navigation.item.titles.50-characters.label'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./width/width.view.vue'),
    meta: { apiKey: 'have-title-above-70-characters', bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'TitleWidth70',
    path: '/titles/width/70-characters/:scrappID?',
    props: {
      description: translate('titles.width.70.view.table.description'),
      title: translate('navigation.item.titles.70-characters.label'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./width/width.view.vue'),
    meta: {
      apiKey: 'have-not-number-in-title',
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'NotNumberInTitle',
    path: '/titles/number/:scrappID?',
    props: {
      description: translate('titles.number.view.table.description'),
      hideContentWidth: true,
      title: translate('navigation.item.titles.number.label'),
    },
  },
];

export default TitlesRouter;
