import { Auth0 } from '@/auth';

const ImagesRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./all/all.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'AllImages',
    path: '/images/all/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./long-alt/long-alt.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'LongAlt',
    path: '/images/long-alt/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./missing-alt/missing-alt.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'MissingAlt',
    path: '/images/missing-alt/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./large-size/large-size.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'LargeSize',
    path: '/images/large-size/:scrappID?',
  },
];

export default ImagesRouter;
