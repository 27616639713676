import { Auth0 } from '@/auth';
import { FilterHeaderDataType } from '@/components/data-table/data-table.model';
import { translate } from '@/utils';

const PagesRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'Pages',
    path: '/pages/:scrappID?',
    props: {
      apiKey: 'all',
      description: translate('pages.all.view.table.description'),
      title: translate('pages.all.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'Pages-2xx',
    path: '/pages/2xx/:scrappID?',
    props: {
      apiKey: '2xx',
      description: translate('pages.2xx.view.table.description'),
      title: translate('pages.2xx.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'Pages-4xx',
    path: '/pages/4xx/:scrappID?',
    props: {
      apiKey: '4xx',
      description: translate('pages.4xx.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
        {
          field: 'performance',
          filterable: true,
          header: translate('data.table.component.header.loading.time.label'),
          sortable: true,
          type: FilterHeaderDataType.Number,
        },
      ],
      title: translate('pages.4xx.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'Pages-5xx',
    path: '/pages/5xx/:scrappID?',
    props: {
      apiKey: '5xx',
      description: translate('pages.5xx.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('pages.5xx.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'Pages-Unauthorized',
    path: '/pages/unauthorized/:scrappID?',
    props: {
      apiKey: 'unauthorized',
      description: translate('pages.unauthorized.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
        {
          field: 'indexable',
          header: translate('data.table.component.header.indexable.label'),
          sortable: true,
        },
        {
          field: 'performance',
          filterable: true,
          header: translate('data.table.component.header.loading.time.label'),
          sortable: true,
          type: FilterHeaderDataType.Number,
        },
      ],
      title: translate('pages.unauthorized.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'HaveParamsAndIndex',
    path: '/pages/params-and-index/:scrappID?',
    props: {
      apiKey: 'have-params-and-index',
      description: translate('pages.have.params.and.index.view.table.description'),
      title: translate('pages.have.params.and.index.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'Pages-Indexable',
    path: '/pages/indexable/:scrappID?',
    props: {
      apiKey: 'indexable',
      description: translate('pages.indexable.view.table.description'),
      title: translate('pages.indexable.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'Pages-Not-Indexable',
    path: '/pages/not-indexable/:scrappID?',
    props: {
      apiKey: 'not-indexable',
      description: translate('pages.not-indexable.view.table.description'),
      title: translate('pages.not-indexable.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./disallow-by-robots/disallow-by-robots.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'DisallowByRobots',
    path: '/pages/disallow-by-robots/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./not-custom-404/not-custom-404.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'NotCustom404',
    path: '/pages/not-custom-404/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./content-html-ratio/content-html-ratio.view.vue'),
    meta: { apiKey: 'max-content-html-ratio', bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'MaxContentHtmlRatio',
    path: '/pages/content-html-ratio/max/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./content-html-ratio/content-html-ratio.view.vue'),
    meta: { apiKey: 'min-content-html-ratio', bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'MinContentHtmlRatio',
    path: '/pages/content-html-ratio/min/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'HighExternalLinks',
    path: '/pages/high-external-links/:scrappID?',
    props: {
      apiKey: 'high-external-links',
      description: translate('pages.high-external-links.view.table.description'),
      title: translate('pages.high-external-links.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'MissingLangAttr',
    path: '/pages/missing-lang-attr/:scrappID?',
    props: {
      apiKey: 'not-lang-attr',
      description: translate('pages.missing-lang-attr.view.table.description'),
      title: translate('pages.missing-lang-attr.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'MissingOpengraph',
    path: '/pages/missing-opengraph/:scrappID?',
    props: {
      apiKey: 'have-not-opengraph',
      description: translate('pages.missing-opengraph.view.table.description'),
      title: translate('pages.missing-opengraph.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,

      requiresSidebar: true,
    },
    name: 'MissingSemanticTags',
    path: '/pages/missing-semantic-tag/:scrappID?',
    props: {
      apiKey: 'not-semantic-tag',
      description: translate('pages.missing-semantic-tags.view.table.description'),
      title: translate('pages.missing-semantic-tags.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,

      requiresSidebar: true,
    },
    name: 'MissingSchemaOrg',
    path: '/pages/missing-schema-org/:scrappID?',
    props: {
      apiKey: 'have-not-schema-org',
      description: translate('pages.missing-schema-org.view.table.description'),
      title: translate('pages.missing-schema-org.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,

      requiresSidebar: true,
    },
    name: 'MetaRefresh',
    path: '/pages/meta-refresh/:scrappID?',
    props: {
      apiKey: 'have-meta-refresh',
      description: translate('pages.meta-refresh.view.table.description'),
      title: translate('pages.meta-refresh.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,

      requiresSidebar: true,
    },
    name: 'MissingContent',
    path: '/pages/missing-content/:scrappID?',
    props: {
      apiKey: 'have-not-content',
      description: translate('pages.missing-content.view.table.description'),
      title: translate('pages.missing-content.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../more-content/more-content.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,

      requiresSidebar: true,
    },
    name: 'MoreContent',
    path: '/pages/more-content/:scrappID?',
    props: {
      description: translate('pages.missing-content.view.table.description'),
      title: translate('pages.missing-content.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./missing-hreflangs/missing-hreflangs.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'MissingHreflangs',
    path: '/pages/missing-hreflangs/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./invalid-script-place/invalid-script-place.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'InvalidScriptPlace',
    path: '/pages/invalid-script-place/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./not-work-without-js/not-work-without-js.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'NotWorkWithoutJS',
    path: '/pages/not-work-without-js/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./not-secure/not-secure.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'NotSecure',
    path: '/pages/not-secure/:scrappID?',
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'InvalidCanonical',
    path: '/pages/invalid-canonical/:scrappID?',
    props: {
      apiKey: 'have-invalid-canonical',
      description: translate('pages.invalid-canonical.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('pages.invalid-canonical.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,

      requiresSidebar: true,
    },
    name: 'LazyLoading',
    path: '/pages/disable-lazy-loading/:scrappID?',
    props: {
      apiKey: 'not-lazy-loading',
      description: translate('pages.disabled-lazy-loading.view.table.description'),
      title: translate('pages.disabled-lazy-loading.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,

      requiresSidebar: true,
    },
    name: 'TooMuchScripts',
    path: '/pages/too-much-scripts/:scrappID?',
    props: {
      apiKey: 'have-too-much-script',
      description: translate('pages.too.much.scripts.view.table.description'),
      title: translate('pages.too.much.scripts.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'TooLargeCode',
    path: '/pages/too-large-code/:scrappID?',
    props: {
      apiKey: 'have-too-large-code',
      description: translate('pages.too.large.code.view.table.description'),
      title: translate('pages.too.large.code.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'Pdf',
    path: '/pdf/:scrappID?',
    props: {
      apiKey: 'is-pdf',
      description: translate('pages.pdf.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
      ],
      title: translate('pages.pdf.view.table.title'),
    },
  },
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('../universal-view/universal-view.view.vue'),
    meta: {
      bellActivate: true,
      requiresNavAndSidebar: true,
      requiresSidebar: true,
    },
    name: 'PagesSchemaOrgStars',
    path: '/pages/schema-org-stars/:scrappID?',
    props: {
      apiKey: 'schema-org-stars',
      description: translate('pages.schema-org-stars.view.table.description'),
      headers: [
        {
          field: 'url',
          filterable: true,
          header: translate('data.table.component.header.url.label'),
          sortable: true,
          type: FilterHeaderDataType.Text,
        },
        {
          field: 'schemaOrgStars',
          filterable: true,
          header: translate('data.table.component.header.schema-org-stars.label'),
          sortable: true,
          type: FilterHeaderDataType.Number,
        },
      ],
      title: translate('pages.schema-org-stars.view.table.title'),
    },
  },
];

export default PagesRouter;
