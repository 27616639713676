import { Auth0 } from '@/auth';

const IframesRouter = [
  {
    beforeEnter: Auth0.routeGuard,
    component: () => import('./all/all.view.vue'),
    meta: { bellActivate: true, requiresNavAndSidebar: true, requiresSidebar: true },
    name: 'IframesAll',
    path: '/iframes/all/:scrappID?',
  },
];

export default IframesRouter;
